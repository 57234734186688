import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import { IMG_Mercadolibre } from '../../../components/images/docs/mercadolibre';
import AmazonLogo from '../../../components/images/amazon';
import { IMG_Amazon } from '../../../components/images/docs/amazon';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesDefault: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <AmazonLogo />
            </div>
            <h2>
                Configurar impuestos
            </h2>
            <p>
                De manera predeterminada, los productos cuentan con 16% de IVA, puedes cambiar esta opción en la misma vista que editas las claves de SAT.
            </p>
            <ImageFooted explanation={`La vista de pedidos muestra los preductos activos y pausados, pausados son algo transparentes, los activos se muestran normales.`}>
                <IMG_Amazon file={`productos`} />
            </ImageFooted>
            <ImageFooted explanation={`Puedes cambiar el IVA entre 16% y 0%, así como agregar IEPS cuando sea necesario.`}>
                <IMG_Mercadolibre file={`productoEdit`} />
            </ImageFooted>
            <p>
                Desde la vista de Productos, puedes entrar a la sección de <b>Editar múltiples listados</b> para poder configurar categorías completas.
            </p>
            <SectionNavigate next={`/ecommerce/amazon/productos-multiple`} previous={`/ecommerce/amazon/facturacion-global`} />
        </div>
    </Layout>
)

export default ShopifyClavesDefault;